* { font-family: 'Inter', sans-serif; }

body { font-style: normal; font-weight: 400; font-size: 20px; line-height: 20px; margin: auto; }
a { text-decoration: none; }
img { max-width: 100%; max-height: 100%; width: auto; height: auto; }

.main_header { background: #F9F8F7; box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08); position: relative; }
.main_header--wrap { max-width: 1440px; margin: 0 auto; }
.main_header--inner { padding: 56px 50px 45px; }

.button1 { font-weight: 600 !important; }

.Dashboard{ background: #F9F8F7; }
.Dashboard--wrap { max-width: 1440px; margin: 0 auto; }
.Dashboard--grid { display: grid; grid-template-columns: 250px 1fr; grid-gap: 0; }
.Dashboard--item.Dashboard--item--sidebar { box-shadow: 0px 4px 20px rgb(0 0 0 / 8%); }
.Dashboard--item.Dashboard--item--content { padding: 68px 101px 68px 53px; }

.shadowBoxStandard { box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%); }
.DashboardTitle { display: flex; padding: 15px 29px 18px 38px; margin: auto auto 57px 0; width: fit-content; background: #F9F8F7; box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08); border-radius: 13px; }
.DashboardTitle--txt { font-style: normal; font-weight: 600; font-size: 20px; line-height: 20px; margin: auto; }
.DashboardFrom { background: #F9F8F7; box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08); border-radius: 13px; padding: 31px 53px 45px 38px; margin: 0 auto 67px; }
.DashboardFrom h1 { font-style: normal; font-weight: 700; font-size: 24px; line-height: 20px; color: #212529; margin-right: 30px; }

.DashboardVestmapTitle h2 { font-style: normal; font-weight: 700; font-size: 24px; line-height: 20px; color: #212529; margin-right: 30px; }
.DashboardVestmapsFlexBox { display: flex; background-color: #FFFFFF; padding: 15px; box-shadow: 0 2px 2px 3px #c2c2c2; border-radius: 10px; }
.DashboardVestmapsImageBox { margin: auto 0; max-width: 300px; }
.DashboardVestmapsImageBox img { display: block; border-radius: 10px; }
.DashboardVestmapsContentBox { text-align: left; margin: auto auto auto 0; }
.DashboardVestmapsContentBox h3 { font-style: normal; font-weight: 700; font-size: 21px; line-height: 32px; color: #171717; margin: 0 auto 25px; }

.DashboardVestmapsFlexBox { display: grid; grid-template-columns: 215px 1fr; grid-gap: 23px; align-items: center; padding: 16px 24px 16px 16px; background: #FFFFFF; box-shadow: 0px 0px 1px rgba(13, 13, 13, 0.9), 0px 1px 3px #0D0D0D; border-radius: 16px; min-height: 163px; }
.DashboardVestmapsImageBox img { border-radius: 10px; display: block; width: 100%; height: auto; }

.DashboardVestmapsImageBox { position: relative; }
.DashboardVestmapsImageBox .DashboardVestmapsImageLoader { position: absolute; top: 50%; left: 50%; width: 64px; z-index: 9999; transform: translate(-50%, -50%); }

.DashboardVestmapsContentBoxProcess img { width: 24px; display: block; margin: auto 0; margin-right: 15px; }
.DashboardVestmapsContentBoxProcess { display: flex; align-items: center; font-size: 17px; font-weight: normal; font-style: normal; line-height: 1; color: #c5c5c5; }
.DashboardVestmapsContentBoxTitle1, .DashboardVestmapsContentBoxTitle2 { background: #eee; width: 100%; height: 20px; margin: 0 auto 15px; } .DashboardVestmapsContentBoxTitle2 { background: #f6f6f6; width: 50%; margin-left: 0; } .DashboardVestmapsContentBoxTitle { margin: 0 auto auto; width: 100%; } .DashboardVestmapsContentBox { margin: auto auto auto 0; text-align: left; display: flex; flex-wrap: wrap; height: 100%; width: 100%; }

@media only screen and (max-width: 1200px) {
    .DashboardVestmapsImageBox { max-width: 200px; }
}

@media only screen and (max-width: 1024px) {
    .DashboardVestmapsImageBox { max-width: 150px; }
}